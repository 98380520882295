<template>
  <div>
    <div class="app-wrapper">
      <div class="container py-5">
        <div class="col-lg-10 mx-auto p-0">
          <div class="py-5 d-flex align-items-center justify-content-between">
            <h3 class="ana_h3 mb-0">Edit Link</h3>
            <div>
              <span class="cancel" @click="$router.go(-1)">
                <i class="ti-close"></i>
              </span>
            </div>
          </div>
          <div
            class="mt-3 mb-3 d-flex align-items-center justify-content-center py-5"
            v-if="isLoading"
          >
            <div class="loader"></div>
          </div>
          <div v-if="!isLoading">
            <div class="py-3">
              <div class="mb-3">
                <small class="text-muted">You are editing </small>
              </div>
              <p class="small font-weight-bold mb-0 text-muted">
                {{ link.longURL }}
              </p>
              <p class="font-weight-bold ana_paragraph">{{ linkTitle }}</p>
            </div>
            <form class="form" @submit.prevent="updateURL">
              <div class="form-group">
                <label for="link_title" class="ana-label"
                  >Title of this link <small>(This is optional)</small></label
                >
                <input
                  type="text"
                  c
                  name="link_title"
                  class="ana-input w-100"
                  v-model="link.title"
                />
              </div>
              <div class="form-group">
                <label for="prefix" class="ana-label">URL Prefix</label>
                <div class="input-group mb-3 ana-input__group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon2"
                      >myana.com.ng/</span
                    >
                  </div>
                  <input
                    type="text"
                    name="prefix"
                    class="form-control ana-input"
                    v-model="link.urlCode"
                    placeholder="tesla"
                    required
                  />
                </div>
                <div class="text-left">
                  <button
                    class="p-1 action-btn ana-btn__grey"
                    type="button"
                    @click="generateShortId()"
                  >
                    Generate unique URL
                  </button>
                </div>
              </div>
              <div class="mt-3 mb-3" v-if="isLinkLoading">
                <div class="linear-loader"></div>
              </div>
              <div class="py-4">
                <button
                  type="submit"
                  class="ana-btn ana-btn__fill"
                  :disabled="isLinkLoading"
                >
                  Update Link
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { nanoid } from "nanoid";
export default {
  metaInfo: {
    title: "Edit Link - Ana app",
  },
  name: "Edit",
  data() {
    return {
      link: {},
      isLoading: false,
      isLinkLoading: false,
      linkTitle: "",
    };
  },
  mounted() {
    this.isLoading = true;
    const linkID = this.$route.params.id;
    axios
      .get(`my-links/${linkID}`)
      .then((res) => {
        this.link = res.data.link;
        this.linkTitle = res.data.link.title;
        this.isLoading = false;
      })
      .catch((err) => {
        console.log(err);
        this.$router.push("/dash/overview");
        this.isLoading = false;
      });
  },
  methods: {
    updateURL() {
      if (this.link.urlCode.trim().length < 2) {
        this.$toast(`Ensure unique url is at least 2 characters long`, {
          timeout: 5000,
          type: "error",
        });
      } else {
        this.isLinkLoading = true;
        const updated_link = {
          title: this.link.title,
          urlCode: this.link.urlCode,
        };
        axios
          .put(`my-links/edit/${this.link._id}`, updated_link)
          .then((res) => {
            this.isLinkLoading = false;
            this.$toast(`Link has been updated successfully`, {
              timeout: 3000,
              type: "success",
            });
            this.$router.push({
              path: `/dash/link/${this.link._id}/${res.data.link.slug}`,
              params: {
                linkID: this.link._id,
              },
            });
          })
          .catch((err) => {
            this.$toast(`${err.response.data.message}`, {
              timeout: 2000,
              type: "error",
            });
            this.isLinkLoading = false;
            console.log(err);
          });
      }
    },
    generateShortId() {
      this.link.urlCode = nanoid(8);
    },
  },
};
</script>
